import Moment from 'moment';
import VueScrollTo from 'vue-scrollto';

import { numberFormats } from '@/i18n.js';
import { AUTHENTICATION_RETURN_PAGE_STORAGE } from '@/constants.js';
import { userTypeEnum as UserTypeEnum } from '@/GeneratedModels/UserTypeEnum.js';
import { transactionFeesPlanTypeEnum as TransactionFeesPlanTypeEnum } from '@/GeneratedModels/TransactionFeesPlanTypeEnum.js';
import { auctionInvoiceStatusTypeEnum as AuctionInvoiceStatusTypeEnum } from '@/GeneratedModels/AuctionInvoiceStatusTypeEnum.js';
import { routeName, routeTitle } from '@/router/routing.js';
import { formatPhoneNumber } from '@/scripts/phoneUtils.js';
import { gotoAuctionLogin, navigateTo } from '@/scripts/redirection.js';
import SignUpInReasons from '@/scripts/SignUpInReasons.js';
import { AuctionStatus } from '@/store/auction/state.js';
import { auctionStatusEnum as AuctionStatusEnum } from '@/GeneratedModels/AuctionStatusEnum.js';
import { invoiceStatusEnum as InvoiceStatusEnum } from '@/GeneratedModels/InvoiceStatusEnum.js';
import { invoiceTypeEnum as InvoiceTypeEnum } from '@/GeneratedModels/InvoiceTypeEnum.js';

const supportedLocales = Object.freeze(['fr-CA', 'en-CA']);

export function multiwatch(keys, handler) {
  const result = {};
  for (const i in keys) {
    result[keys[i]] = handler;
  }
  return result;
}

export const validation = {
  methods: {
    addValidationError(fieldName, errorMessage) {
      const field = this.$validator.fields.find({ name: fieldName, scope: this.$options.scope });
      this.$validator.errors.add({
        id: field.id,
        field: fieldName,
        msg: errorMessage,
        scope: this.$options.scope
      });
      field.setFlags({
        invalid: true,
        valid: false,
        validated: true
      });
    },
    hasChange(originalValue, updatedValue, filter) {
      let original = typeof originalValue === 'function' ? originalValue() : originalValue,
        updated = typeof updatedValue === 'function' ? updatedValue() : updatedValue;

      if (!Array.isArray(original)) {

        if (filter && typeof filter === 'function') {
          original = filter(original);
          updated = filter(updated);
        }


        const jsonOriginal = JSON.stringify({ ...original }),
          jsonUpdated = JSON.stringify({ ...updated });

        return jsonOriginal != jsonUpdated;
      } else {
        for (const index in original) {

          if (filter && typeof filter === 'function') {
            original = filter(original[index]);
            updated = filter(updated[index]);
          }

          const jsonOriginal = JSON.stringify({ ...original[index] }),
            jsonUpdated = JSON.stringify({ ...updated[index] });
          if (jsonOriginal != jsonUpdated) {
            return true;
          }
        }
        return false;
      }
    },
    scrollToError() {
      const errors = Array.from(document.getElementsByClassName("error")),
        element = errors.find((el) => window.getComputedStyle(el).display !== 'none' && el.nodeName === 'SPAN');
      if (element) {
        VueScrollTo.scrollTo(element, {
          container: 'body',
          duration: 350,
          easing: 'linear',
          offset: window.screen ? -window.screen.availHeight / 2 : 350,
          cancelable: false
        });
      }
    }
  }
};

export const notification = {
  data() {
    return {
      successNotificationVisible: false,
      errorNotificationVisible: false,
      cancelNotificationVisible: false,
      canHideSuccessCheck: false,
      canHideCancelCheck: false
    };
  },
  computed: {
    disableAction() {
      return !this.isDirty || this.responsePending;
    },
    disableCancelButton() {
      return this.disableAction && !this.cancelNotificationVisible;
    },
    disableSaveButton() {
      return this.disableAction && !this.successNotificationVisible;
    }
  },
  methods: {
    showErrorToast() {
      this.$toast.error(this.$t('settings.enterprise.toastError'), {
        toastClassName: 'error',
        timeout: false,
        onClose: this.hideErrorNotification
      });
    },
    hideErrorNotification() {
      this.errorNotificationVisible = false;
    },
    showSuccessToast(toggleVisibilityState = true, onClose = this.hideSuccessNotification, text = this.$t('settings.enterprise.toastSucceed')) {
      this.$toast.success((text), {
        toastClassName: 'success',
        timeout: 3000,
        onClose
      });
      if (toggleVisibilityState) {
        this.successNotificationVisible = true;
      }
    },
    hideSuccessNotification() {
      this.successNotificationVisible = false;
    },

    showCancelToast() {
      this.$toast.warning(this.$t('settings.enterprise.toastCancel'), {
        toastClassName: 'cancel',
        timeout: 3000,
        onClose: this.hideCancelNotification
      });

      this.cancelNotificationVisible = true;
    },
    hideCancelNotification() {
      this.cancelNotificationVisible = false;
    },
    onSaveSuccessMouseLeave() {
      if (this.successNotificationVisible) {
        this.canHideSuccessCheck = true;
      }
    },
    onSaveSuccessMouseOver() {
      if (this.canHideSuccessCheck) {
        this.hideSuccessNotification();
        this.canHideSuccessCheck = false;
      }
    },
    onCancelSuccessMouseLeave() {
      if (this.cancelNotificationVisible) {
        this.canHideCancelCheck = true;
      }
    },
    onCancelSuccessMouseOver() {
      if (this.canHideCancelCheck) {
        this.hideCancelNotification();
        this.canHideCancelCheck = false;
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$toast.clear();
    next();
  }
};

export const locale = {
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    supportedLocales: () => supportedLocales,
    auctionDefaultLocale() {
      return this.$store.state.auction.auction && this.$store.state.auction.auction.defaultLocale;
    },
    additionalLocales() {

      if (this.$store.state.auction.auction) {
        return this.$store.state.auction.auction.supportedLocales.filter(x => x !== this.$store.state.auction.auction.defaultLocale);
      }
      else {
        return this.notSelectedLocales;
      }
    },
    orderedLocales() {
      // Toujours mettre la langue par défaut en premier
      return [this.auctionDefaultLocale, ...(this.additionalLocales || [])];
    },
    notSelectedLocales() {
      return this.supportedLocales.filter(x => x !== this.lang);
    },
    auctionSupportedLocales() {
      return this.$store.state.auction.auction.supportedLocales;
    }
  },
  methods: {
    getLocalizedProperty(dict, lang = this.lang) {
      if (dict[lang] && dict[lang].length > 0) {
        return dict[lang];
      }
      if (this.$store.state.auction.auction && dict[this.$store.state.auction.auction.defaultLocale] && dict[this.$store.state.auction.auction.defaultLocale].length > 0) {
        return dict[this.$store.state.auction.auction.defaultLocale];
      }

      return Object.values(dict).find(x => x != null && x.length > 0);
    },
    isLocalizedPropertyEmpty(dict, lang = this.lang) {
      return !dict || !this.getLocalizedProperty(dict, lang);
    },
    emptyLocalizedProperty() {
      const result = {};
      for (const key in supportedLocales) {
        result[supportedLocales[key]] = '';
      }
      return result;
    },
    compareEachLocalizedProperty(a, b) {
      const duplicateLocales = [];
      for (const key of supportedLocales) {
        if (a[key].trim() != "" && a[key].trim() === b[key].trim()) {
          duplicateLocales.push(key);
        }
      }
      return duplicateLocales;
    },
    compareLocalizedProperty(a, b) {
      for (const key of supportedLocales) {
        if (a[key].trim() !== b[key].trim()) {
          return false;
        }
      }
      return true;
    },
    getLocaleFriendlyName(locale, feminine = false) {
      const localeToCompare = locale.toLowerCase();
      if (this.lang.toLowerCase() === 'fr-ca') {
        if (localeToCompare === 'fr-ca') {
          return feminine ? 'francaise' : 'français';
        } else if (localeToCompare === 'en-ca') {
          return feminine ? 'anglaise' : 'anglais';
        }
      } else if (this.lang.toLowerCase() === 'en-ca') {
        if (localeToCompare === 'fr-ca') {
          return 'french';
        } else if (localeToCompare === 'en-ca') {
          return 'english';
        }
      }
    }
  }
};

export const routes = {
  methods: {
    routeName(name, { params, query, hash } = { params: {}, query: {}, hash: '' }) {
      return { name: routeName(this.$i18n.locale, name), params, query, hash: hash ? `#${hash}` : undefined };
    },
    routeTitle(part) {
      return routeTitle(part);
    },
    isRoute(...routeNames) {
      return (routeNames || []).find(x => routeName(this.$i18n.locale, x) === this.$route.name);
    },
    getAdminHomeRouteName(auctionStatus) {
      return auctionStatus === AuctionStatus.pending ? "admin.dashboard" : "admin.performances";
    },
    getStageIndex(auctionStatus) {
      switch (auctionStatus) {
        case AuctionStatusEnum.Pending:
          return AuctionStatus.pending;
        case AuctionStatusEnum.InProgress:
          return AuctionStatus.ongoing;
        case AuctionStatusEnum.closed:
          return AuctionStatus.over;
      }
    },
    isCurrentRouteChildOf(routeName) {
      return this.$router.currentRoute.matched.map((item) => {
        return item.name;
      }).includes(routeName);
    }
  },
  computed: {
    adminHomeRouteName() {
      return this.getAdminHomeRouteName(this.$store.state.auction.status);
    },
    currentRouteNameWithoutLang() {
      return this.$router.currentRoute.name.substr(this.$router.currentRoute.name.indexOf('.') + 1, this.$router.currentRoute.name.length);
    }
  }
};

export const meta = {
  mixins: [routes],
  metaInfo() {
    return {
      title: this.$t(`metaTitles.${this.currentRouteNameWithoutLang}`),
      meta: [
        { property: 'og:title', content: this.$t(`metaTitles.${this.currentRouteNameWithoutLang}`), vmid: 'og:title' }
      ]
    };
  },
  computed: {
    ogTitle() {
      return this.$t(`metaTitles.${this.currentRouteNameWithoutLang}`);
    }
  }
};

export const dates = {
  methods: {
    humanReadableTimestamp(value) {
      const moment = Moment(value);
      return moment.format(this.$t('datetime.time'));
    },
    humanReadableDate(value) {
      return Moment(value).format(this.$t('datetime.date'));
    },
    formattedDateTime(value) {
      return Moment(value).format(this.$t('datetime.datetimetext'));
    },
    formattedShortDateTime(value) {
      return Moment(value).format(this.$t('datetime.datetime'));
    },
    formattedShortDate(value) {
      return Moment(value).format(this.$t('datetime.shortDate'));
    },
    formattedYearMonthDayDate(value) {
      return Moment(value).format(this.$t('datetime.yearMonthDay'));
    }
  }
};

export const images = {
  mixins: [locale],
  computed: {
    logoToShow() {
      if (this.$store.state.auction.auction.useAuctionLogo) {
        return this.getLocalizedProperty(this.$store.state.auction.auction.auctionLogo);
      }
      else {
        return this.getLocalizedProperty(this.$store.state.organization.organization.logo);
      }
    },
    organizationLogo() {
      return this.$store.state.organization.organization.logo;
    }
  },
  methods: {
    imageIdToUrl(imageId, fromOrganization) {
      //Les imageId qui commence par "data:image/", son les image en base64 qu'on viens d'importer et non des id du blobStorage
      if (imageId && imageId.startsWith("data:image/")) {
        return imageId;
      }

      if (fromOrganization) {
        return this.$store.state.app.appSettings.imageBaseUrl.replace("{auctionId}", this.$store.state.organization.organization.id).replace("{imageId}", imageId);
      }

      return this.$store.state.app.appSettings.imageBaseUrl.replace("{auctionId}", this.$store.state.auction.auction.id).replace("{imageId}", imageId);
    }
  }
};

export const auction = {
  computed: {
    currentAuction() {
      return this.$store.state.auction.auction;
    },
    auctionPending() {
      return this.$store.state.auction.status === AuctionStatus.pending;
    },
    auctionOngoing() {
      return this.$store.state.auction.status === AuctionStatus.ongoing;
    },
    auctionExtended() {
      return this.$store.state.auction.status === AuctionStatus.extended;
    },
    auctionWasExtended() {
      return this.currentAuction.effectiveEndDate > this.currentAuction.endDate;
    },
    auctionOver() {
      return this.$store.state.auction.status >= AuctionStatus.over;
    },
    auctionClosed() {
      return this.currentAuction.closed;
    },
    auctionOverOrClosed() {
      return this.auctionOver || this.auctionClosed;
    },
    auctionClosedInvoiceSent() {
      return this.$store.state.auction.status === AuctionStatus.overInvoiceSent;
    },
    auctionPaid() {
      return this.currentAuction.isPaid;
    },
    usesAutomaticExtension() {
      return this.currentAuction.usesAutomaticExtension;
    },
    hideParticipantNames() {
      return this.currentAuction.hideParticipantNames;
    },
    hasValidTaxNumbers() {
      return this.$store.state.auction.auction.areTaxNumbersValid;
    },
    isStripeUsable() {
      return this.currentAuction && this.currentAuction.allowStripeCheckoutPayment && !this.isStripeAccountDisabled;
    },
    isStripeAccountEnabled() {
      return this.$store.state.organization.organization.stripePaymentSettings.stripeAccountPaymentEnabled &&
        this.$store.state.organization.organization.stripePaymentSettings.stripeAccountChargesEnabled;
    },
    isStripeAccountOnlyChargeEnabled() {
      return !this.$store.state.organization.organization.stripePaymentSettings.stripeAccountPaymentEnabled &&
        this.$store.state.organization.organization.stripePaymentSettings.stripeAccountChargesEnabled;
    },
    isStripeAccountDisabled() {
      return !this.$store.state.organization.organization.stripePaymentSettings.stripeAccountPaymentEnabled &&
        !this.$store.state.organization.organization.stripePaymentSettings.stripeAccountChargesEnabled;
    },
    isVoluntaryCoverageFeesEnabled() {
      if (this.currentAuction.allowStripeCheckoutPayment) {
        return this.currentAuction.transactionFeesPlanType == TransactionFeesPlanTypeEnum.Volontary;
      }
      return false;
    },
    isContributiveFeesEnabled() {
      if (this.currentAuction.allowStripeCheckoutPayment) {
        return this.currentAuction.transactionFeesPlanType == TransactionFeesPlanTypeEnum.Contributive;
      }
      return false;
    },
    isCreditCardRequired() {
      return this.currentAuction.allowStripeCheckoutPayment && this.currentAuction.creditCardRequired;
    },
    isTransactional() {
      return this.currentAuction.isFree;
    },
    hasDoneAutomaticPayment() {
      return this.currentAuction.hasDoneAutomaticPayment;
    },
    auctionInvoiceAsBeenSend() {
      return this.currentAuction.auctionInvoiceStatusType == AuctionInvoiceStatusTypeEnum.InvoiceSent ||
        this.currentAuction.auctionInvoiceStatusType == AuctionInvoiceStatusTypeEnum.FeesInvoicePaid;
    },
    auctionStatusEnum: () => AuctionStatusEnum
  },
  methods: {
    getAuctionStatus(auction) {
      const now = new Date();
      const startDate = new Date(auction.startDate);

      if (auction.closed) {
        return this.auctionStatusEnum.Closed;
      }
      else if (startDate <= now) {
        return this.auctionStatusEnum.InProgress;
      }
      else {
        return this.auctionStatusEnum.Pending;
      }
    }
  }
};

export const auctionRules = {
  mixins: [locale],
  computed: {
    auctionRules() {
      return this.$store.state.auction.rules;
    }
  },
  methods: {
    isRuleActivated(rule) {
      return rule != null && rule.isActivated && !this.isLocalizedPropertyEmpty(rule.text);
    }
  }
};

export const organization = {
  computed: {
    currentOrganization() {
      return this.$store.state.organization.organization;
    },
    currentOrganizationAuctions() {
      return this.$store.state.organization.organizationAuctions;
    },
    canDuplicateAuction() {
      return this.$store.state.organization.nbOfAuction;
    },
    hasRemainingAuction() {
      return this.currentOrganization.remainingAuction > 0;
    }
  },
  methods: {
    getAuctionLocalizedProperty(dict, lang, defaultLocale) {
      if (dict[lang] && dict[lang].length > 0) {
        return dict[lang];
      }
      return dict[defaultLocale];
    },
    getAuctionImageUrl(auctionId, imageId) {
      if (imageId) {
        return this.$store.state.app.appSettings.imageBaseUrl.replace("{auctionId}", auctionId).replace("{imageId}", imageId);
      }

      return null;
    },
    async createOrDuplicateAuction(modalRef) {
      //Envoie directement à la création d'encan si on peut pas dupliquer l'encan
      if (!this.canDuplicateAuction) {
        this.$store.dispatch('organization/setAuctionToDuplicate', null);
        this.$router.push({ name: this.routeName('organization.createAuction').name });
      }
      else {
        const modalResult = await this.$refs[modalRef].show();

        if (modalResult) {
          this.$router.push({ name: this.routeName('organization.createAuction').name, query: { duplicateFromAuction: true } });
        }
        else if (modalResult != null) {
          this.$store.dispatch('organization/setAuctionToDuplicate', null);
          this.$router.push({ name: this.routeName('organization.createAuction').name });
        }
      }
    }
  }
};

function createdThisSessionKey(userId, auctionId) {
  return `createdThisSession_${userId}_${auctionId}`;
}

function userUnderstandOverTimeStartKey(userId, auctionId) {
  return `userUnderstandOverTimeStart_${userId}_${auctionId}`;
}

export const user = {
  computed: {
    Roles: () => UserTypeEnum,
    userId() { return this.currentUser ? this.currentUser.id : null; },
    currentUser() { return this.$store.state.user.user; },
    userIsLogged() { return !!this.$store.state.user.user; },
    hasAuthenticatedUserRights() { return this.currentUser && this.currentUser.type >= UserTypeEnum.Participant; },
    hasAssistantRights() { return this.currentUser && this.currentUser.type >= UserTypeEnum.Assistant; },
    hasAuctionManagerRights() { return this.currentUser && this.currentUser.type >= UserTypeEnum.AuctionManager; },
    hasAdministratorRights() { return this.currentUser && this.currentUser.type >= UserTypeEnum.Administrator; },
    hasAccountOwnerRights() { return this.currentUser && this.currentUser.type >= UserTypeEnum.AccountOwner; },
    hasSuperadministratorRights() { return this.currentUser && this.currentUser.type >= UserTypeEnum.Superadministrator; },
    hasUnpaidInvoices() {
      return this.$store.state.user.invoices.some(x => (x.status == InvoiceStatusEnum.Unpaid || x.status == InvoiceStatusEnum.Error) && x.type == InvoiceTypeEnum.Standard);
    },
    userUnderstandOverTimeMode() { return this.currentUser && this.currentUser.understandOvertimeMode; },
    currentPaymentInfo() { return this.$store.state.user.paymentInfo; }
  },
  methods: {
    userCannotEditRole(userTypeEnum) {
      return !this.hasSuperadministratorRights &&
        (userTypeEnum == UserTypeEnum.AccountOwner || (userTypeEnum == UserTypeEnum.Administrator && !this.hasAdministratorRights));
    },
    userHasMinRole(userTypeEnum) { return this.currentUser && this.currentUser.type >= userTypeEnum; },
    gotoAuctionLogin(redirect = true, reason = SignUpInReasons.authenticationNeeded, redirectPath, userEmail = null, authenticationStatus = null) {
      if (redirect) {
        gotoAuctionLogin(this.$router, redirect, reason, redirectPath || this.$route.path, userEmail, authenticationStatus);
      } else {
        navigateTo(() => {
          gotoAuctionLogin(this.$router, redirect, reason, redirectPath || this.$route.path, userEmail, authenticationStatus);
        });
      }
    },
    saveReturnPage() {
      if (this.$router.currentRoute.name &&
        this.$router.currentRoute.name != routeName(this.$i18n.locale, 'auctionUnderConstruction') &&
        this.$router.currentRoute.name != routeName(this.$i18n.locale, 'auctionValidateUserEmail')) {
        sessionStorage.setItem(AUTHENTICATION_RETURN_PAGE_STORAGE, this.$router.currentRoute.path);
      }
    },
    isAuctionCreatedThisSession(auctionId) {
      return this.userId && sessionStorage.getItem(createdThisSessionKey(this.userId, auctionId));
    },
    setAuctionCreatedThisSession(auctionId) {
      if (this.userId) {
        sessionStorage.setItem(createdThisSessionKey(this.userId, auctionId), true);
      }
    },
    removeAuctionCreatedThisSession(auctionId) {
      if (this.userId) {
        sessionStorage.removeItem(createdThisSessionKey(this.userId, auctionId));
      }
    },
    userUnderstandOverTimeStart(auctionId) {
      return this.userId && localStorage[userUnderstandOverTimeStartKey(this.userId, auctionId)];
    },
    updateUserUnderstandOvertimeStart(auctionId) {
      if (this.userId) {
        localStorage[userUnderstandOverTimeStartKey(this.userId, auctionId)] = true;
      }
    }
  }
};

export const formatter = {
  methods: {
    formatPhoneNumber
  }
};

function auctionCurrencyFormatter(key, overrides = {}) {
  return new Intl.NumberFormat(this.$i18n.locale, {
    ...numberFormats[this.$i18n.locale][key],
    currency: overrides.currency || this.$store.state.auction.auction.currency,
    currencyDisplay: overrides.currencyDisplay || 'narrowSymbol',
    ...overrides
  });  
}

function formatWithAuctionCurrencySymbol(format, v) {
  if (this.$te(`currencies.${this.$store.state.auction.auction.currency}.symbol`)) {
    return format.formatToParts(v)
      .map(x => x.type === 'currency' ? this.$t(`currencies.${this.$store.state.auction.auction.currency}.symbol`) : x.value)
      .join('');
  }
  return format.format(v);
}

function formatAmount(format, v) {
  return format.formatToParts(v)
    .filter(x => x.type !== 'currency')
    .map(x => x.value)
    .join('')
    .trim();
}

export const currency = {
  computed: {
    supportedCurrency() { return Object.freeze(['CAD', 'USD']); },
    // Le symbole de la devise de l'encan en cours.
    // À utiliser uniquement pour afficher le symbole tout seul.
    // Pour formatter des montants, utiliser plutôt 'formatCurrency' ou 'formatCurrencyNoCents'
    auctionCurrencyNarrowSymbol() {
      return auctionCurrencyFormatter.call(this, 'currency').formatToParts(null).find(x => x.type === 'currency').value;
    },
    auctionCurrencySymbol() {
      if (this.$te(`currencies.${this.$store.state.auction.auction.currency}.symbol`)) {
        return this.$t(`currencies.${this.$store.state.auction.auction.currency}.symbol`);
      }
      return auctionCurrencyFormatter.call(this, 'currency', { currencyDisplay: 'symbol' }).formatToParts(null).find(x => x.type === 'currency').value;
    },
    isAuctionCurrencySymbolBeforeAmount() {
      return auctionCurrencyFormatter.call(this, 'currency').formatToParts(null)[0].type === 'currency';
    }
  },
  methods: {
    formatNarrowSymbol(currency) {
      return currency.replace("US", "");
    },
    // Formatte la valeur en incluant les sous, dans la langue actuelle, dans la devise de l'encan en cours
    formatCurrency(v, overrides) {
      return this.formatNarrowSymbol(
        auctionCurrencyFormatter.call(this, 'currency', overrides).format(v)
      );
    },
    // Formatte la valeur en n'incluant pas les sous, dans la langue actuelle, dans la devise de l'encan en cours
    formatCurrencyNoCents(v, overrides) {
      return this.formatNarrowSymbol(
        auctionCurrencyFormatter.call(this, 'currencyNoCents', overrides).format(v)
      );
    },
    // Formatte la valeur en incluant les sous s'il y en a, dans la langue actuelle, dans la devise de l'encan en cours
    formatCurrencyAutoCents(v, overrides) {
      return (v % 1 != 0) ? this.formatCurrency(v, overrides) : this.formatCurrencyNoCents(v, overrides);
    },
    formatCurrencyAmount(v, overrides) {
      return this.formatNarrowSymbol(
        formatAmount.call(this, auctionCurrencyFormatter.call(this, 'currency', overrides), v)
      );
    },
    formatCurrencyAmountNoCents(v, overrides) {
      return this.formatNarrowSymbol(
        formatAmount.call(this, auctionCurrencyFormatter.call(this, 'currencyNoCents', overrides), v)
      );
    },
    formatCurrencyAmountAutoCents(v, overrides) {
      return (v % 1 != 0) ? this.formatCurrencyAmount(v, overrides) : this.formatCurrencyAmountNoCents(v, overrides);
    },
    formatCurrencyWithAuctionCurrencySymbol(v, overrides) {
      return formatWithAuctionCurrencySymbol.call(
        this,
        auctionCurrencyFormatter.call(this, 'currency', { currencyDisplay: 'symbol', ...overrides }),
        v);
    },
    formatCurrencyNoCentsWithAuctionCurrencySymbol(v, overrides) {
      return formatWithAuctionCurrencySymbol.call(
        this,
        auctionCurrencyFormatter.call(this, 'currencyNoCents', { currencyDisplay: 'symbol', ...overrides }),
        v);
    },
    formatCurrencyAutoCentsWithAuctionCurrencySymbol(v, overrides) {
      return (v % 1 != 0) ? this.formatCurrencyWithAuctionCurrencySymbol(v, overrides) : this.formatCurrencyNoCentsWithAuctionCurrencySymbol(v, overrides);
    },
    formatPercent(v, overrides) {
      return auctionCurrencyFormatter.call(this, 'percent', overrides).format(v);
    },
    formatPercentNoDecimals(v, overrides) {
      return auctionCurrencyFormatter.call(this, 'percentNoDecimals', overrides).format(v);
    }
  }
};

export const signup = {
  computed: {
    currentOrganizationId() {
      return this.$store.state.signup.organization.id;
    },
    hasOrganization() {
      return !!this.currentOrganizationId;
    }
  }
};

export const scrollToItem = {
  computed: {
    itemListLastVisitedItem: {
      get() {
        return this.$store.state.auction.itemListLastVisitedItem;
      },
      set(value) {
        this.$store.state.auction.itemListLastVisitedItem = value;
      }
    }
  },
  methods: {
    async restorePreviousNavigation() {
      if (this.itemListLastVisitedItem != null) {
        this.scrollTo = "item-" + this.itemListLastVisitedItem;
        this.itemListLastVisitedItem = null;
        this.itemListPage = this.currentPage;
        this.loading = false;
        this.handleScroll();
      }
    },
    handleScroll() {
      if (this.scrollTo != null) {
        const htmlElement = document.getElementById(String(this.scrollTo));
        if (htmlElement) {
          VueScrollTo.scrollTo(htmlElement);
        }
      } else {
        const topOfPage = document.getElementById("app");
        if (topOfPage) {
          VueScrollTo.scrollTo(topOfPage);
        }
      }
    }
  }
};

export const common = [images, routes, user, dates, auction, formatter, currency];

// Mixins par défaut
export default common;
