import Vue from 'vue';
import Item from '@/classes/Item.js';
import { dumbMutation } from '@/store/util.js';
import { itemTypeEnum as ItemTypeEnum } from '@/GeneratedModels/ItemTypeEnum.js';
import { invoiceStatusEnum as InvoiceStatusEnum } from '@/GeneratedModels/InvoiceStatusEnum.js';

export const NotificationMessage = Object.freeze({
  HIGHEST_BID: 'highestBid',
  EQUAL_BID: 'equalBid',
  HIGHEST_SYMBOLIC_BID: 'highestSymbolicBid',
  EQUAL_SYMBOLIC_BID: 'equalSymbolicBid',
  OUTBID: 'outbid',
  ERROR: 'error',
  SUCCESS: 'success',
  UNAVAILABLE_FOR_BID: 'itemUnavailableForBids',
  UNAVAILABLE_FOR_SYMBOLIC_BID: 'itemUnavailableForSymbolicBid'
});

export const NotificationType = Object.freeze({
  HIGHEST: 'highest',
  EQUAL: 'equal',
  OUTBID: 'outbid',
  ERROR: 'error',
  SUCCESS: 'success',
  UNAVAILABLE: 'itemUnavailableForBids'
});

function handleFeaturedItem(state, item) {
  if (item.featured) {
    Vue.set(state.featuredItems, item.id, item);
  } else {
    Vue.delete(state.featuredItems, item.id);
  }
}

export default {
  setCurrentAuction: dumbMutation('auction'),
  setAllowStripeCheckoutPayment: dumbMutation('allowStripeCheckoutPayment'),
  setItems(state, values) {
    // Indexer les items pour simplifier l'accès
    if (!state.items) {
      Vue.set(state, 'items', {});
    }
    for (const item of values) {
      if (item.deletedOn == null) {
        const itemInstance = new Item(item, this.state.app.connectionStaleSequence);
        Vue.set(state.items, item.id, itemInstance);

        if (item.removedOn == null) {
          handleFeaturedItem(state, itemInstance);
        }
      }
    }
  },
  addItem(state, newItem) {
    const itemInstance = new Item(newItem, this.state.app.connectionStaleSequence);

    Vue.set(state.items, newItem.id, itemInstance);
    handleFeaturedItem(state, itemInstance);
  },
  updateItem(state, newItem) {
    const itemInstance = new Item(newItem, this.state.app.connectionStaleSequence);
    Vue.set(state.items, newItem.id, itemInstance);
    handleFeaturedItem(state, itemInstance);
  },
  deleteItem(state, itemId) {
    Vue.delete(state.items, itemId);
    Vue.delete(state.featuredItems, itemId);
  },
  deleteItems(state, itemIds) {
    for (const itemId of itemIds) {
      Vue.delete(state.items, itemId);
      Vue.delete(state.featuredItems, itemId);
    }
  },
  participantsStatusChanged(state, event) {
    if (!event.status) {
      for (const itemId in state.items) {
        const item = state.items[itemId];
        if (item.bids) {
          Vue.set(item, 'bids', item.bids.filter(x => !event.userIds.includes(x.userId)));
        }
      }
      for (const donationId in state.donations) {
        const donation = state.donations[donationId];
        if (event.userIds.includes(donation.userId)) {
          Vue.delete(state.donations, donation.id);
        }
      }
    }
  },
  highestBidEvent(state, event) {
    event.createdOn = new Date(event.createdOn);
    const item = state.items[event.itemId];
    if (item && item.bids) {
      item.modifiedOn = new Date();
      item.bids.unshift(event);
    }

    state.highestBids.unshift(event);
    // this est le store
    if (state.highestBids.length > this.state.app.appSettings.newsFeedBidCount) {
      state.highestBids.splice(this.state.app.appSettings.newsFeedBidCount, state.highestBids.length - this.state.app.appSettings.newsFeedBidCount);
    }

    if (typeof state.itemReassignmentListeners[event.itemId] === 'function') {
      state.itemReassignmentListeners[event.itemId]();
    }
  },
  authHighestBidEvent(state, event) {
    event.createdOn = new Date(event.createdOn);
    const item = state.items[event.itemId];
    if (item && item.bids) {

      if (item.type === ItemTypeEnum.Symbolic) {
        Vue.set(state.notifications, event.itemId, {
          type: NotificationType.HIGHEST,
          message: NotificationMessage.HIGHEST_SYMBOLIC_BID
        });
      }
      else {
        Vue.set(state.notifications, event.itemId, {
          type: NotificationType.HIGHEST,
          message: NotificationMessage.HIGHEST_BID
        });
      }

      clearTimeout(state.bidsTimeout[item.id]);
      delete state.bidsTimeout[item.id];
    }
  },
  equalBidEvent(state, event) {
    const item = state.items[event.itemId];
    if (item && state.correlationIds[item.id] && state.correlationIds[item.id] === event.correlationId) {

      if (item.type === ItemTypeEnum.Symbolic) {
        Vue.set(state.notifications, event.itemId, {
          type: NotificationType.EQUAL,
          message: NotificationMessage.EQUAL_SYMBOLIC_BID
        });
      }
      else {
        Vue.set(state.notifications, event.itemId, {
          type: NotificationType.EQUAL,
          message: NotificationMessage.EQUAL_BID
        });
      }

      clearTimeout(state.bidsTimeout[item.id]);
      delete state.bidsTimeout[item.id];
    }
  },
  outbidBidEvent(state, event) {
    const item = state.items[event.itemId];
    if (item && state.correlationIds[item.id] && state.correlationIds[item.id] === event.correlationId) {
      Vue.set(state.notifications, event.itemId, {
        type: NotificationType.OUTBID,
        message: NotificationMessage.OUTBID
      });

      clearTimeout(state.bidsTimeout[item.id]);
      delete state.bidsTimeout[item.id];
    }
  },
  failedBidEvent(state, { itemId, correlationId }) {
    const item = state.items[itemId];

    if (item) {
      Vue.set(state.notifications, itemId, {
        type: NotificationType.ERROR,
        message: NotificationMessage.ERROR
      });

      if (!correlationId || state.correlationIds[itemId] === correlationId) {
        clearTimeout(state.bidsTimeout[item.id]);
        delete state.bidsTimeout[item.id];
      }
    }
  },
  itemUnavailableForBidsEvent(state, event) {
    const item = state.items[event.itemId];

    if (item) {
      if (item.type === ItemTypeEnum.Symbolic) {
        Vue.set(state.notifications, item.id, {
          type: NotificationType.UNAVAILABLE,
          message: NotificationMessage.UNAVAILABLE_FOR_SYMBOLIC_BID
        });
      }
      else {
        Vue.set(state.notifications, item.id, {
          type: NotificationType.UNAVAILABLE,
          message: NotificationMessage.UNAVAILABLE_FOR_BID
        });
      }

      clearTimeout(state.bidsTimeout[item.id]);
      delete state.bidsTimeout[item.id];
    }
  },
  itemAvailabilityChanged(state, event) {
    const item = state.items[event.itemId];
    if (item) {
      item.isAvailableForBids = event.isAvailableForBids;
    }
  },
  itemIsVisibleChanged(state, event) {
    const item = state.items[event.itemId];
    if (item) {
      if (!event.isVisible) {
        Vue.delete(state.items, event.itemId);
        Vue.delete(state.featuredItems, event.itemId);
      }
    }
  },
  successDonationEvent(state, { userId }) {
    Vue.set(state.notifications, userId, {
      type: NotificationType.SUCCESS,
      message: NotificationMessage.SUCCESS
    });
  },
  donationPayNowEvent(state, event) {
    Vue.set(state.notifications, event.userId, {
      type: NotificationType.SUCCESS,
      message: NotificationMessage.SUCCESS,
      invoiceId: event.invoiceId
    });
  },
  failedDonationEvent(state, { userId }) {
    Vue.set(state.notifications, userId, {
      type: NotificationType.ERROR,
      message: NotificationMessage.ERROR
    });
  },
  auctionPaymentEvent(state, event) {
    Vue.set(state.paymentEvents, event, {
      type: NotificationType.SUCCESS,
      message: NotificationMessage.SUCCESS
    });
  },
  auctionPaymentSuccess(state, event) {
    Vue.set(state.paymentEvents, event, {
      type: NotificationType.SUCCESS,
      message: NotificationMessage.SUCCESS
    });
  },
  auctionPaymentFailure(state, event) {
    Vue.set(state.paymentEvents, event, {
      type: NotificationType.SUCCESS,
      message: NotificationMessage.SUCCESS
    });
  },
  bidCanceledEvent(state, bidCanceledEvent) {
    const item = state.items[bidCanceledEvent.itemId];
    if (item && item.bids) {
      for (let i = 0; i < item.bids.length; i++) {
        if (item.bids[i].correlationId === bidCanceledEvent.correlationId) {
          const canceledBid = item.bids.splice(i, 1);
          item.canceledBids.push(...canceledBid);
          break;
        }
      }
    }
    state.highestBids = state.highestBids.filter(x => x.correlationId !== bidCanceledEvent.correlationId);
  },
  donationCanceledEvent(state, donationCanceledEvent) {
    Vue.delete(state.donations, donationCanceledEvent.id.value);
  },
  auctionUpdated(state, auction) {
    Vue.set(state, 'auction', auction);
  },
  auctionVisibilityChanged(state, data) {
    Vue.set(state.auction, 'visible', data.auctionVisibility);
  },
  clearNotification(state, itemId) {
    Vue.delete(state.notifications, itemId);
  },
  storeCorrelationId(state, { itemId, correlationId, timeoutId }) {
    Vue.set(state.correlationIds, itemId, correlationId);
    Vue.set(state.bidsTimeout, itemId, timeoutId);
  },
  setError: dumbMutation('error'),
  setLastImpersonatedUsers: dumbMutation('lastImpersonatedUsers'),
  setLastImpersonatedUsersRetrieved(state, success) {
    if (success) {
      state._lastImpersonatedUsersPromiseResolved();
    } else {
      state._lastImpersonatedUsersPromiseRejected();
    }
  },
  setHighestBids: dumbMutation('highestBids'),
  setDonations(state, values) {
    if (!state.donations) {
      Vue.set(state, 'donations', {});
    }
    for (const i in values) {
      const donation = values[i];
      Vue.set(state.donations, donation.id, donation);
    }
  },
  addItemNotificationSuccess(state, itemId) {
    Vue.set(state.notifications, itemId, {
      type: NotificationType.SUCCESS,
      message: NotificationMessage.SUCCESS
    });
  },
  setInvoices(state, invoices) {
    state.invoices = invoices;
  },
  setInvoice(state, event) {
    let index = state.invoices.findIndex(i => i.id === event.invoiceId);
    if (index == -1) {
      index = state.invoices.length;
    }

    Vue.set(state.invoices, index, event.invoice);
  },
  setRefundResponses(state, refundResponses) {
    state.refundResponses = refundResponses;
  },
  setShowCategoryFilters: dumbMutation('showCategoryFilters'),
  setShowLocationFilters: dumbMutation('showLocationFilters'),
  setShowPartnerFilters: dumbMutation('showPartnerFilters'),
  setShowTypeFilters: dumbMutation('showTypeFilters'),
  setRaisedAmount(state, { amount, serverTime }) {
    state.raisedAmount = amount;
    this.commit('auction/updateServerTimeOffset', serverTime);
  },
  updateServerTimeOffset(state, serverTime) {
    if (serverTime !== null && serverTime !== undefined) {
      state.serverTimeOffset = new Date() - new Date(serverTime);
      if (process.env.NODE_ENV === 'development') {
        console.debug('serverTimeOffset', state.serverTimeOffset); // eslint-disable-line no-console
      }
    }
  },
  erroneousItemTypeForBidEvent(state, event) {
    const item = state.items[event.itemId];
    if (item && state.correlationIds[item.id] && state.correlationIds[item.id] === event.correlationId) {
      Vue.set(state.notifications, event.itemId, {
        type: NotificationType.ERROR,
        message: NotificationMessage.ERROR
      });

      clearTimeout(state.bidsTimeout[item.id]);
      delete state.bidsTimeout[item.id];
    }
  },
  itemsIsFeatureChangedEvent(state, event) {
    for (const item of event) {
      if (item.featured) {
        Vue.set(state.featuredItems, item.id, item);
      } else {
        Vue.delete(state.featuredItems, item.id);
      }
    }
  },
  itemCategoryChanged(state, event) {
    const item = state.items[event.itemId];
    if (item) {
      item.category = event.itemClassification;
    }
  },
  itemLocationChanged(state, event) {
    const item = state.items[event.itemId];
    if (item) {
      item.location = event.itemClassification;
    }
  },
  invoicePaymentStatusChanged(state, event) {
    const invoice = state.invoices.find(x => x.id == event.invoiceId);
    if (invoice) {
      invoice.status = InvoiceStatusEnum.Paid;
      invoice.payment.push(event);
    }
  },
  invoiceReminderPaymentChanged(state, event) {
    const invoice = state.invoices.find(x => x.id == event);
    if (invoice) {
      invoice.reminderPaymentMailDate = Date.now();
    }
  },
  invoiceStatusError(state, event) {
    const invoice = state.invoices.find(x => x.id == event);
    if (invoice) {
      invoice.status = InvoiceStatusEnum.Error;
    }
  },
  invoiceStatusUpdatedEvent(state, event) {
    const invoice = state.invoices.find(x => x.id === event.invoiceId);
    if (invoice) {
      Vue.set(invoice, 'status', event.invoiceStatus);
    }
  },
  auctionRulesUpdated(state, rules) {
    state.rules = rules;
  }
};
