
import i18n from '@/i18n';
import Localize from '@/components/Localize.vue';
import { userTypeEnum as Roles } from '@/GeneratedModels/UserTypeEnum.js';

// Les 3 méthodes suivantes sont dupliquées de ./routing.js; les importer ici cause une référence cyclique dans les tests unitaires inexplicablement.

function routePath(lang, name) {
  return i18n.t(`routes.${name}`, lang);
}

function routeName(lang, name) {
  return `${lang}.${name}`;
}

function routeTitle(lang, name) {
  return i18n.t(`titles.${name}`, lang);
}

export default (defaultLanguage) => [
  {
    path: ``,
    redirect: `/${defaultLanguage}`
  },
  ...getRoutes(defaultLanguage)
];

const truncateEntityIdParam = id => id && id.substring(0, 24);

// Les pages qui ne définissent pas de 'title' dans leurs meta doivent gérer elles-mêmes le titre de la page
export const getRoutes = (lang) => {
  return [{
    path: `/${lang}`,
    component: () => import(/* webpackChunkName: "AppPublic" */ '@/views/AppPublic.vue'),
    children: [
      //ICI Mettre toutes les pages de la sections publique/organisation/signup etc, avant ce qui est propre à un encan
      {
        path: `/`,
        name: "portal",
        redirect: () => { window.location.href = 'https://www.followmybid.com'; return false; }
      },
      {
        path: routePath(lang, 'error'),
        name: routeName(lang, 'error'),
        component: () => import(/* webpackChunkName: "Error" */ '@/views/Error.vue'),
        meta: {
          title: routeTitle(lang, 'error')
        }
      },
      {
        path: routePath(lang, 'pageNotFound'),
        name: routeName(lang, 'pageNotFound'),
        component: () => import(/* webpackChunkName: "PageNotFound" */ '@/views/PageNotFound.vue'),
        meta: {
          title: routeTitle(lang, 'pageNotFound')
        }
      },
      {
        path: routePath(lang, 'privacyPolicy'),
        name: routeName(lang, 'privacyPolicy'),
        component: () => import(/* webpackChunkName: "privacyPolicy" */ '@/views/PrivacyPolicy.vue'),
        meta: {
          title: routeTitle(lang, 'privacyPolicy')
        }
      },
      {
        path: routePath(lang, 'termsOfUse'),
        name: routeName(lang, 'termsOfUse'),
        component: () => import(/* webpackChunkName: "termsOfUse" */ '@/views/TermsOfUse.vue'),
        meta: {
          title: routeTitle(lang, 'termsOfUse')
        }
      },
      //{
      //  path: routePath(lang, 'supplier'),
      //  name: routeName(lang, 'supplier'),
      //  component: () => import(/* webpackChunkName: "Supplier" */ '@/views/Supplier.vue'),
      //  meta: {
      //    title: routeTitle(lang, 'supplier')
      //  }
      //},
      {
        path: `${routePath(lang, 'supplier')}/:supplierId`,
        name: routeName(lang, 'supplier'),
        props: true,
        component: () => import(/* webpackChunkName: "Supplier" */ '@/views/Supplier.vue'),
        meta: {
          title: routeTitle(lang, 'supplier')
        }
      },
      {
        path: `${routePath(lang, 'itemsCatalogue')}/:auctionId`,
        name: routeName(lang, 'itemsCatalogueAuction'),
        props: true,
        component: () => import(/* webpackChunkName: "itemsCatalogue" */ '@/views/ItemsCatalogue.vue'),
        meta: {
          title: routeTitle(lang, 'itemsCatalogue')
        }
      },
      {
        path: routePath(lang, 'itemsCatalogue'),
        name: routeName(lang, 'itemsCatalogue'),
        component: () => import(/* webpackChunkName: "itemsCatalogue" */ '@/views/ItemsCatalogue.vue'),
        meta: {
          title: routeTitle(lang, 'itemsCatalogue')
        }
      },
      {
        path: `${routePath(lang, 'purchaseContract')}/:type`,
        name: routeName(lang, 'purchaseContract'),
        props: route => ({
          type: route.params.type,
          userId: route.query.userId,
          isTransactional: (route.query.isTransactional === 'true'),
          priceId: route.query.priceId,
          nbAuction: route.query.nbAuction,
          organizationName: route.query.organizationName
        }),
        component: () => import(/* webpackChunkName: "purchaseContract" */ '@/views/PurchaseContract.vue'),
        meta: {
          title: routeTitle(lang, 'purchaseContract')
        }
      },
      {
        //La route de base du signup est copiée dans AuctionUrl.vue, si modifiée ici elle doit l'être aussi dans la vue
        path: routePath(lang, 'signup.base'),
        name: routeName(lang, 'signup.base'),
        props: route => ({
          organizationId: route.query.organizationId,
          pay: route.query.pay
        }),
        component: () => import(/* webpackChunkName: "OwnerSignup" */ '@/views/signup/OwnerSignup.vue'),
        meta: {
          title: routeTitle(lang, 'signup.base')
        },
        children: [
          {
            path: routePath(lang, 'signup.account'),
            name: routeName(lang, 'signup.account'),
            component: () => import(/* webpackChunkName: "Account" */ '@/views/signup/Account.vue'),
            meta: {
              title: routeTitle(lang, 'signup.account')
            }
          },
          {
            path: routePath(lang, 'signup.organization'),
            props: route => ({
              accountCreated: (route.query.accountCreated === 'true')
            }),
            name: routeName(lang, 'signup.organization'),
            component: () => import(/* webpackChunkName: "Organization" */ '@/views/signup/Organization.vue'),
            meta: {
              title: routeTitle(lang, 'signup.organization')
            }
          },
          {
            path: routePath(lang, 'signup.participant'),
            name: routeName(lang, 'signup.participant'),
            component: () => import(/* webpackChunkName: "Participant" */ '@/views/signup/Participant.vue')
          }

          // NOTE(struchon) Si vous ajoutez une route ici, assurez-vous qu'elle est exclue dans AuctionUrl.vue et dans AuctionUrlService.cs
        ]
      },
      {
        path: routePath(lang, 'organization.base'),
        name: routeName(lang, 'organization.base'),
        props: route => ({
          organizationId: route.query.organizationId
        }),
        component: () => import(/* webpackChunkName: "Organization" */ '@/views/organization/AppBaseOrganization.vue'),
        meta: {
          title: routeTitle(lang, 'organization.base')
        },
        children: [
          {
            path: routePath(lang, 'organization.error'),
            name: routeName(lang, 'organization.error'),
            component: () => import(/* webpackChunkName: "ErrorPage" */ '@/views/organization/ErrorPage.vue'),
            meta: {
              title: routeTitle(lang, 'organization.error')
            }
          },
          {
            path: routePath(lang, 'organization.profile'),
            name: routeName(lang, 'organization.profile'),
            component: () => import(/* webpackChunkName: "Profile" */ '@/views/organization/Profile.vue'),
            meta: {
              title: routeTitle(lang, 'organization.profile')
            }
          },
          {
            path: routePath(lang, 'organization.invoice'),
            name: routeName(lang, 'organization.invoice'),
            component: () => import(/* webpackChunkName: "Invoice" */ '@/views/organization/Invoice.vue'),
            meta: {
              title: routeTitle(lang, 'organization.invoice')
            }
          },
          {
            path: routePath(lang, 'organization.stripeSettings'),
            name: routeName(lang, 'organization.stripeSettings'),
            component: () => import(/* webpackChunkName: "StripeSettings" */ '@/views/organization/StripeSettings.vue'),
            meta: {
              title: routeTitle(lang, 'organization.stripeSettings')
            }
          },
          {
            path: routePath(lang, 'organization.auctions'),
            name: routeName(lang, 'organization.auctions'),
            component: () => import(/* webpackChunkName: "Auctions" */ '@/views/organization/Auctions.vue'),
            meta: {
              title: routeTitle(lang, 'organization.auctions')
            }
          },
          {
            path: routePath(lang, 'organization.createAuction'),
            name: routeName(lang, 'organization.createAuction'),
            props: route => ({
              duplicateFromAuction: (route.query.duplicateFromAuction === 'true' || route.query.duplicateFromAuction),
              skipFirstStep: (route.query.skipFirstStep === 'true' || route.query.skipFirstStep)
            }),
            component: () => import(/* webpackChunkName: "CreateAuction" */ '@/views/organization/CreateAuction.vue'),
            meta: {
              title: routeTitle(lang, 'organization.createAuction')
            }
          },
          {
            path: routePath(lang, 'organization.roleManagement'),
            name: routeName(lang, 'organization.roleManagement'),
            component: () => import(/* webpackChunkName: "RoleManagement" */ '@/views/organization/RoleManagement.vue'),
            meta: {
              title: routeTitle(lang, 'organization.roleManagement')
            }
          },
          {
            path: routePath(lang, 'organization.roleManagementDetails'),
            name: routeName(lang, 'organization.roleManagementDetails'),
            component: () => import(/* webpackChunkName: "roleManagementDetails" */ '@/views/organization/RoleManagementDetails.vue'),
            meta: {
              title: routeTitle(lang, 'organization.roleManagementDetails')
            }
          },
          {
            path: `${routePath(lang, 'organization.roleManagement')}/:userId`,
            name: routeName(lang, 'organization.roleManagementEdit'),
            component: () => import(/* webpackChunkName: "RoleManagementEdit" */ '@/views/organization/RoleManagementEdit.vue'),
            props: route => ({
              selectedUserId: route.params.userId
            }),
            meta: {
              title: routeTitle(lang, 'organization.roleManagement')
            }
          },
          {
            path: routePath(lang, 'organization.help'),
            name: routeName(lang, 'organization.help'),
            component: () => import(/* webpackChunkName: "Help" */ '@/views/help/Help.vue'),
            meta: {
              title: routeTitle(lang, 'organization.help')
            }
          }
        ]
      },
      {
        path: `:slug`,
        redirect: { name: routeName(lang, 'auction') },
        component: Localize,
        children: [
          {
            path: ``,
            component: () => import(/* webpackChunkName: "AppBaseAuction" */ '@/views/AppBaseAuction.vue'),
            children: [
              {
                path: routePath(lang, 'authentication.base'),
                component: () => import(/* webpackChunkName: "AppAuthentication" */ '@/views/AppAuthentication.vue'),
                children: [
                  {
                    path: '',
                    name: routeName(lang, 'authentication.signupin'),
                    props: route => route.query,
                    component: () => import(/* webpackChunkName: "Signupin" */ '@/views/authentication/Signupin.vue'),
                    meta: {
                      title: routeTitle(lang, 'authentication.signupin')
                    }
                  },
                  {
                    path: routePath(lang, 'authentication.login'),
                    props: route => ({
                      redirectUrl: route.query.redirectUrl,
                      invitationId: route.query.invitationId,
                      userEmail: route.query.userEmail,
                      authenticationStatus: route.query.authenticationStatus
                    }),
                    name: routeName(lang, 'authentication.login'),
                    component: () => import(/* webpackChunkName: "Login" */ '@/views/authentication/Login.vue'),
                    meta: {
                      title: routeTitle(lang, 'authentication.login')
                    }
                  }
                ]
              },
              {
                path: routePath(lang, 'admin.base'), props: true,
                name: routeName(lang, 'admin.base'),
                component: () => import(/* webpackChunkName: "AppAdmin" */ '@/views/AppAdmin.vue'),
                meta: {
                  minRole: Roles.AuctionManager,
                  title: routeTitle(lang, 'admin.base')
                },
                children: [
                  {
                    path: routePath(lang, 'admin.dashboard'),
                    name: routeName(lang, 'admin.dashboard'),
                    component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/admin/Dashboard.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.dashboard')
                    }
                  },
                  {
                    path: routePath(lang, 'admin.item_list'),
                    name: routeName(lang, 'admin.item_list'),
                    component: () => import(/* webpackChunkName: "ItemList" */ '@/views/admin/ItemList.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.item_list')
                    }
                  },
                  {
                    path: `${routePath(lang, 'admin.add_item')}`,
                    name: routeName(lang, 'admin.add_item'),
                    component: () => import(/* webpackChunkName: "ItemEdit" */ '@/views/admin/ItemEdit.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.add_item')
                    }
                  },
                  {
                    path: `${routePath(lang, 'admin.edit_item')}/:itemId`,
                    props: true,
                    name: routeName(lang, 'admin.edit_item'),
                    component: () => import(/* webpackChunkName: "ItemEdit" */ '@/views/admin/ItemEdit.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.edit_item')
                    }
                  },
                  {
                    path: `${routePath(lang, 'admin.add_item_unique')}`,
                    name: routeName(lang, 'admin.add_item_unique'),
                    component: () => import(/* webpackChunkName: "ItemEdit" */ '@/views/admin/ItemUniqueEdit.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.add_item_unique')
                    }
                  },
                  {
                    path: `${routePath(lang, 'admin.edit_item_unique')}/:itemId`,
                    props: true,
                    name: routeName(lang, 'admin.edit_item_unique'),
                    component: () => import(/* webpackChunkName: "ItemEdit" */ '@/views/admin/ItemUniqueEdit.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.edit_item_unique')
                    }
                  },
                  {
                    path: routePath(lang, 'admin.participants'),
                    props: { detailPage: 'admin.participant', userType: Roles.Participant, currentRouteName: 'admin.participants' },
                    name: routeName(lang, 'admin.participants'),
                    component: () => import(/* webpackChunkName: "UserList" */ '@/views/admin/UserList.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.participants')
                    }
                  },
                  {
                    path: routePath(lang, 'admin.assistants'),
                    props: { detailPage: 'admin.assistant', userType: Roles.Assistant, currentRouteName: 'admin.assistants' },
                    name: routeName(lang, 'admin.assistants'),
                    component: () => import(/* webpackChunkName: "UserList" */ '@/views/admin/UserList.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.assistants')
                    }
                  },
                  {
                    path: routePath(lang, 'admin.administrators'),
                    props: { detailPage: 'admin.administrator', userType: Roles.AuctionManager, currentRouteName: 'admin.administrators' },
                    name: routeName(lang, 'admin.administrators'),
                    component: () => import(/* webpackChunkName: "UserList" */ '@/views/admin/UserList.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.administrators')
                    }
                  },
                  {
                    path: `${routePath(lang, 'admin.participant')}/:userId?`,
                    props: route => ({
                      selectedUserId: route.params.userId,
                      parentPage: { name: routeName(lang, 'admin.participants') },
                      userType: Roles.Participant
                    }),
                    name: routeName(lang, 'admin.participant'),
                    component: () => import(/* webpackChunkName: "UserEdit" */ '@/views/admin/UserEdit.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.participant')
                    }
                  },
                  {
                    path: `${routePath(lang, 'admin.assistant')}/:userId?`,
                    props: route => ({
                      selectedUserId: route.params.userId,
                      parentPage: { name: routeName(lang, 'admin.assistants') },
                      userType: Roles.Assistant
                    }),
                    name: routeName(lang, 'admin.assistant'),
                    component: () => import(/* webpackChunkName: "UserEdit" */ '@/views/admin/UserEdit.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.assistant')
                    }
                  },
                  {
                    path: `${routePath(lang, 'admin.administrator')}/:userId?`,
                    props: route => ({
                      selectedUserId: route.params.userId,
                      parentPage: { name: routeName(lang, 'admin.administrators') },
                      userType: Roles.AuctionManager
                    }),
                    name: routeName(lang, 'admin.administrator'),
                    component: () => import(/* webpackChunkName: "UserEdit" */ '@/views/admin/UserEdit.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.administrator')
                    }
                  },
                  {
                    path: routePath(lang, 'admin.auction_rules'),
                    props: true,
                    name: routeName(lang, 'admin.auction_rules'),
                    component: () => import(/* webpackChunkName: "AuctionRules" */ '@/views/admin/AuctionRules.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.auction_rules')
                    }
                  },
                  {
                    path: routePath(lang, 'admin.auction_settings'), props: true,
                    name: routeName(lang, 'admin.auction_settings'),
                    component: () => import(/* webpackChunkName: "AuctionSettings" */ '@/views/admin/AuctionSettings.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.auction_settings')
                    }
                  },
                  {
                    path: routePath(lang, 'admin.customization_settings'),
                    props: true,
                    name: routeName(lang, 'admin.customization_settings'),
                    component: () => import(/* webpackChunkName: "AuctionCustomization" */ '@/views/admin/AuctionCustomization.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.customization_settings')
                    }
                  },
                  {
                    path: routePath(lang, 'admin.auction_closure'),
                    name: routeName(lang, 'admin.auction_closure'),
                    component: () => import(/* webpackChunkName: "AuctionClosure" */ '@/views/admin/AuctionClosure.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.auction_closure')
                    }
                  },
                  {
                    path: routePath(lang, 'admin.partner_list'), props: true,
                    name: routeName(lang, 'admin.partner_list'),
                    component: () => import(/* webpackChunkName: "PartnerList" */ '@/views/admin/PartnerList.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.partner_list')
                    }
                  },
                  {
                    path: routePath(lang, 'admin.add_partner'),
                    name: routeName(lang, 'admin.add_partner'),
                    props: route => ({
                      partnerCategory: route.params.partnerCategory,
                      isOtherCategory: route.params.isOtherCategory
                    }),
                    component: () => import(/* webpackChunkName: "PartnerEdit" */ '@/views/admin/PartnerEdit.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.add_partner')
                    }
                  },
                  {
                    path: `${routePath(lang, 'admin.edit_partner')}/:partnerId`, props: true,
                    name: routeName(lang, 'admin.edit_partner'),
                    component: () => import(/* webpackChunkName: "PartnerEdit" */ '@/views/admin/PartnerEdit.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.edit_partner')
                    }
                  },
                  {
                    path: routePath(lang, 'admin.add_category_presenter'),
                    name: routeName(lang, 'admin.add_category_presenter'),
                    props: route => ({
                      partnerCategoryType: route.params.partnerCategoryType
                    }),
                    component: () => import(/* webpackChunkName: "PartnerEdit" */ '@/views/admin/PartnerCategoryEdit.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.add_category_presenter')
                    }
                  },
                  {
                    path: routePath(lang, 'admin.add_category_traditional'),
                    name: routeName(lang, 'admin.add_category_traditional'),
                    props: route => ({
                      partnerCategoryType: route.params.partnerCategoryType
                    }),
                    component: () => import(/* webpackChunkName: "PartnerEdit" */ '@/views/admin/PartnerCategoryEdit.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.add_category_traditional')
                    }
                  },
                  {
                    path: `${routePath(lang, 'admin.edit_category_partner')}/:partnerCategoryId`, props: true,
                    name: routeName(lang, 'admin.edit_category_partner'),
                    component: () => import(/* webpackChunkName: "PartnerEdit" */ '@/views/admin/PartnerCategoryEdit.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.edit_category_partner')
                    }
                  },
                  {
                    path: routePath(lang, 'admin.performances'),
                    name: routeName(lang, 'admin.performances'),
                    component: () => import(/* webpackChunkName: "Performances" */ '@/views/admin/Performances.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.performances')
                    }
                  },
                  {
                    path: routePath(lang, 'admin.chatroom'), props: { conversationId: null },
                    name: routeName(lang, 'admin.chatroom'),
                    component: () => import(/* webpackChunkName: "Chatroom" */ '@/views/chatroom/Chatroom.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.chatroom')
                    }
                  },
                  {
                    path: routePath(lang, 'admin.chatroom_draft'), props: { conversationId: 'draft' },
                    name: routeName(lang, 'admin.chatroom_draft'),
                    component: () => import(/* webpackChunkName: "Chatroom" */ '@/views/chatroom/Chatroom.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.chatroom')
                    }
                  },
                  {
                    path: `${routePath(lang, 'admin.chatroom')}/:conversationId`,
                    props: true,
                    name: routeName(lang, 'admin.chatroom_conversation'),
                    component: () => import(/* webpackChunkName: "Chatroom" */ '@/views/chatroom/Chatroom.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.chatroom')
                    }
                  },
                  {
                    path: `${routePath(lang, 'admin.chatroom_user')}/:userId`,
                    props: true,
                    name: routeName(lang, 'admin.chatroom_user'),
                    component: () => import(/* webpackChunkName: "Chatroom" */ '@/views/chatroom/Chatroom.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.chatroom')
                    }
                  },
                  {
                    path: routePath(lang, 'admin.auction_settings_stripe'), props: true,
                    name: routeName(lang, 'admin.auction_settings_stripe'),
                    component: () => import(/* webpackChunkName: "AuctionStripeSettings" */ '@/views/admin/AuctionStripeSettings.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.auction_settings_stripe')
                    }
                  },                 
                  {
                    path: routePath(lang, 'admin.help'),
                    name: routeName(lang, 'admin.help'),
                    component: () => import(/* webpackChunkName: "Help" */ '@/views/help/Help.vue'),
                    meta: {
                      minRole: Roles.AuctionManager,
                      title: routeTitle(lang, 'admin.help')
                    }
                  },
                  {
                    path: routePath(lang, 'admin.admin_error'),
                    name: routeName(lang, 'admin.admin_error'),
                    component: () => import(/* webpackChunkName: "AdminError" */ '@/views/AdminError.vue'),
                    meta: {
                      title: routeTitle(lang, 'admin.admin_error')
                    }
                  }
                ]
              },
              {
                path: ``, props: true,
                name: routeName(lang, 'auction'),
                component: () => import(/* webpackChunkName: "AppAuction" */ '@/views/AppAuction.vue'),
                meta: {
                  title: routeTitle(lang, 'auction')
                },
                children: [
                  {
                    path: routePath(lang, 'participant.item_list'),
                    name: routeName(lang, 'participant.item_list'),
                    component: () => import(/* webpackChunkName: "ItemList" */ '@/views/participant/ItemList.vue')
                  },
                  {
                    path: `${routePath(lang, 'participant.item_details')}/:itemId`,
                    props: route => ({
                      itemId: truncateEntityIdParam(route.params.itemId),
                      applyFilters: route.params.applyFilters,
                      fromItemView: route.params.fromItemView
                    }),
                    name: routeName(lang, 'participant.item_details'),
                    component: () => import(/* webpackChunkName: "ItemDetails" */ '@/views/participant/ItemDetails.vue')
                  },
                  {
                    path: routePath(lang, 'participant.followed_items'),
                    name: routeName(lang, 'participant.followed_items'),
                    component: () => import(/* webpackChunkName: "FollowedItems" */ '@/views/participant/FollowedItems.vue'),
                    meta: {
                      minRole: Roles.Participant
                    }
                  },
                  {
                    path: routePath(lang, 'participant.profile'),
                    name: routeName(lang, 'participant.profile'),
                    component: () => import(/* webpackChunkName: "Profile" */ '@/views/participant/Profile.vue'),
                    meta: {
                      minRole: Roles.Participant
                    }
                  },
                  {
                    path: routePath(lang, 'participant.communications_settings'),
                    name: routeName(lang, 'participant.communications_settings'),
                    component: () => import(/* webpackChunkName: "CommunicationSettings" */ '@/views/participant/CommunicationSettings.vue'),
                    meta: {
                      minRole: Roles.Participant
                    }
                  },
                  {
                    path: routePath(lang, 'participant.invoice'),
                    props: route => ({
                      paymentConfirmed: (route.query.paymentConfirmed == "true")
                    }),
                    name: routeName(lang, 'participant.invoice'),
                    component: () => import(/* webpackChunkName: "Invoice" */ '@/views/participant/Invoice.vue'),
                    meta: {
                      minRole: Roles.Participant
                    }
                  },
                  {
                    path: routePath(lang, 'participant.partner_list'),                    
                    name: routeName(lang, 'participant.partner_list'),
                    component: () => import(/* webpackChunkName: "PartnerList" */ '@/views/participant/PartnerList.vue')
                  },
                  {
                    path: routePath(lang, 'participant.rules'),
                    props: route => ({
                      scrollTo: route.query.scrollTo
                    }),
                    name: routeName(lang, 'participant.rules'),
                    component: () => import(/* webpackChunkName: "Rules" */ '@/views/participant/Rules.vue')
                  },
                  {
                    path: routePath(lang, 'participant.terms'),
                    name: routeName(lang, 'participant.terms'),
                    component: () => import(/* webpackChunkName: "Terms" */ '@/views/participant/Terms.vue'),
                    meta: {
                      title: routeTitle(lang, 'participant.terms')
                    }
                  },
                  {
                    path: routePath(lang, 'participant.contact_us'),
                    name: routeName(lang, 'participant.contact_us'),
                    component: () => import(/* webpackChunkName: "ContactUs" */ '@/views/participant/ContactUs.vue'),
                    props: route => ({
                      tabId: route.query.tabId
                    })
                  },
                  {
                    path: routePath(lang, 'participant.add_paymentInfo'),
                    name: routeName(lang, 'participant.add_paymentInfo'),
                    component: () => import(/* webpackChunkName: "Add_PaymentInfo" */ '@/views/participant/AddPaymentInfo.vue'),
                    props: route => ({
                      skipWantsBearFees: (route.query.skipWantsBearFees === 'true' || route.query.skipWantsBearFees)
                    }),
                    meta: {
                      minRole: Roles.Participant
                    }
                  },
                  {
                    path: routePath(lang, 'participant.transaction_fees_paymentInfo'),
                    name: routeName(lang, 'participant.transaction_fees_paymentInfo'),
                    component: () => import(/* webpackChunkName: "Add_PaymentInfo" */ '@/views/participant/TransactionFeesPaymentInfo.vue'),
                    props: route => ({
                      showSuccesAddCardNotification: (route.query.showSuccesAddCardNotification === 'true' || route.query.showSuccesAddCardNotification),
                      prevRoute: route.params.prevRoute
                    }),
                    meta: {
                      minRole: Roles.Participant
                    }
                  },
                  {
                    path: routePath(lang, 'participant.paymentInfo'),
                    name: routeName(lang, 'participant.paymentInfo'),
                    component: () => import(/* webpackChunkName: "PaymentInfo" */ '@/views/participant/PaymentInfo.vue'),
                    props: route => ({
                      showSuccesAddCardNotification: (route.query.showSuccesAddCardNotification === 'true' || route.query.showSuccesAddCardNotification)
                    }),
                    meta: {
                      minRole: Roles.Participant
                    }
                  },
                  {
                    path: routePath(lang, 'auctionPageNotFound'),
                    name: routeName(lang, 'auctionPageNotFound'),
                    component: () => import(/* webpackChunkName: "AuctionPageNotFound" */ '@/views/AuctionPageNotFound.vue'),
                    meta: {
                      title: routeTitle(lang, 'pageNotFound')
                    }
                  },
                  {
                    path: routePath(lang, 'auctionError'),
                    name: routeName(lang, 'auctionError'),
                    component: () => import(/* webpackChunkName: "AuctionError" */ '@/views/AuctionError.vue'),
                    meta: {
                      title: routeTitle(lang, 'error')
                    }
                  },
                  {
                    path: routePath(lang, 'home'),
                    name: routeName(lang, 'home'),
                    component: () => import(/* webpackChunkName: "Home" */ '@/views/participant/Home.vue'),
                    alias: ''
                  }
                ]
              },
              {
                path: ``,
                component: () => import(/* webpackChunkName: "AppAuction" */ '@/views/AppAuction.vue'),
                props: {
                  startWithDonation: true
                },
                meta: {
                  title: routeTitle(lang, 'auction')
                },
                children: [
                  {
                    path: routePath(lang, 'participant.donate'),
                    name: routeName(lang, 'participant.donate'),
                    component: () => import(/* webpackChunkName: "Home" */ '@/views/participant/Home.vue'),
                    meta: {
                      minRole: Roles.Participant,
                      title: routeTitle(lang, 'participant.donate')
                    }
                  }
                ]
              },
              {
                path: `${routePath(lang, 'participant.invoice_details')}/:invoiceId`,
                props: route => ({
                  invoiceId: route.params.invoiceId,
                  isReceipt: false
                }),
                name: routeName(lang, 'participant.invoice_details'),
                component: () => import(/* webpackChunkName: "PrintInvoice" */ '@/views/participant/PrintInvoice.vue'),
                meta: {
                  title: routeTitle(lang, 'participant.invoice_details')
                }
              },
              {
                path: `${routePath(lang, 'participant.receipt_details')}/:invoiceId`,
                props: route => ({
                  invoiceId: route.params.invoiceId,
                  isReceipt: true
                }),
                name: routeName(lang, 'participant.receipt_details'),
                component: () => import(/* webpackChunkName: "PrintInvoice" */ '@/views/participant/PrintInvoice.vue'),
                meta: {
                  title: routeTitle(lang, 'participant.receipt_details')
                }
              },
              {
                path: routePath(lang, 'participant.add_phone_number'),
                name: routeName(lang, 'participant.add_phone_number'),
                component: () => import(/* webpackChunkName: "Phone" */ '@/views/participant/AddPhoneNumber.vue'),
                meta: {
                  minRole: Roles.Participant
                }
              },
              {
                path: routePath(lang, 'news_feed'), props: true,
                name: routeName(lang, 'news_feed'),
                component: () => import(/* webpackChunkName: "NewsFeed" */ '@/views/NewsFeed.vue'),
                meta: {
                  title: routeTitle(lang, 'news_feed')
                }
              },
              {
                path: routePath(lang, 'auctionUnderConstruction'),
                name: routeName(lang, 'auctionUnderConstruction'),
                component: () => import(/* webpackChunkName: "AuctionUnderConstruction" */ '@/views/AuctionUnderConstruction.vue'),
                meta: {
                  title: routeTitle(lang, 'auctionUnderConstruction')
                }
              },
              {
                path: routePath(lang, 'auctionUnauthorized'),
                name: routeName(lang, 'auctionUnauthorized'),
                component: () => import(/* webpackChunkName: "AuctionUnauthorized" */ '@/views/AuctionUnauthorized.vue'),
                meta: {
                  title: routeTitle(lang, 'auctionUnauthorized')
                }
              },
              {
                path: routePath(lang, 'auctionValidateUserEmail'),
                name: routeName(lang, 'auctionValidateUserEmail'),
                component: () => import(/* webpackChunkName: "AuctionValidateUserEmail" */ '@/views/AuctionValidateUserEmail.vue'),
                meta: {
                  title: routeTitle(lang, 'auctionValidateUserEmail')
                }
              },
              {
                path: `*`,
                redirect: { name: routeName(lang, 'auctionPageNotFound') },
                meta: {
                  title: routeTitle(lang, 'pageNotFound')
                }
              }
            ]
          }
        ]
      },
      {
        path: `*`,
        redirect: { name: routeName(lang, 'pageNotFound') },
        meta: {
          title: routeTitle(lang, 'pageNotFound')
        }
      }
    ]
  }];
};
